import { IMPACTS } from './constants';

const iconSize = '18px';

function closeIcon(color) {
  return `
    <svg 
    height="18px" 
    id="Layer_1"
    version="1.1" 
    viewBox="0 0 512 512"
    width="16px"
    fill=${color}
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"/></svg>`;
}

function impactIcon(impact) {
  switch (impact) {
    case IMPACTS.DEGRADEDPERFORMANCE:
    case IMPACTS.PARTIALOUTAGE:
      return `<svg xmlns="http://www.w3.org/2000/svg"
       height="${iconSize}"
        viewBox="0 0 24 24" 
        width="${iconSize}" 
        fill="#F2994A">
        <path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"/>
        </svg>`;
    case IMPACTS.MAJOROUTAGE:
      return `
          <svg xmlns="http://www.w3.org/2000/svg" 
          height="${iconSize}" 
          viewBox="0 0 24 24" 
          width="${iconSize}" 
          fill="#D51317">
          <path d="M11 15h2v2h-2v-2zm0-8h2v6h-2V7zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>
          </svg>`;
    default:
      return `
            <svg xmlns="http://www.w3.org/2000/svg" 
            height="${iconSize}" 
            viewBox="0 0 24 24" 
            width="${iconSize}" 
            fill="#2F80ED">
            <path d="M0 0h24v24H0V0z" fill="none"/><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/>
            </svg>`;
  }
}

export { closeIcon, impactIcon };
