function setCookie(name, value, minutes) {
  const date = new Date();
  date.setTime(date.getTime() + minutes * 60 * 1000);
  const expires = 'expires=' + date.toUTCString();
  document.cookie = `${name}=${value}; SameSite=Strict; Secure; ${expires}`;
}

function cookieExists(name) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    // Check if the cookie name matches the one you're looking for
    if (cookie.startsWith(name + '=')) {
      return true; // The cookie exists
    }
  }
  return false; // The cookie does not exist
}

export { setCookie, cookieExists };
