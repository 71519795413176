const IMPACTS = {
  DEGRADEDPERFORMANCE: 'DEGRADEDPERFORMANCE',
  PARTIALOUTAGE: 'PARTIALOUTAGE',
  MAJOROUTAGE: 'MAJOROUTAGE',
};

const BASEURL = {
  Development: 'https://byggtjanstdev.instatus.com',
  Production: 'https://status.byggtjanst.se',
};

export { IMPACTS, BASEURL };
