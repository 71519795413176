import { cookieExists } from './cookieHelper';
import { BASEURL, IMPACTS } from './constants';

async function fetchComponents(baseUrl) {
  const res = await fetch(baseUrl + '/components.json', { mode: 'cors' });
  const data = await res.json();
  return data;
}

const statusPageClient = ({ componentId, isDevelopment, summary = false }) => {
  const baseUrl = isDevelopment ? BASEURL.Development : BASEURL.Production;

  const getSummaryStatus = async () => {
    const data = await fetchComponents(baseUrl);

    var components = data.components.filter(
      (d) => d.id === componentId || d.status === IMPACTS.MAJOROUTAGE
    );

    var incidents = components
      .flatMap((obj) => obj.activeIncidents && obj.activeIncidents)
      .filter(
        (v, i, a) =>
          v != undefined && a.findIndex((v2) => v2?.id === v?.id) === i
      )
      .map((incident) => {
        return {
          ...incident,
          started: new Date(incident.started).toISOString(),
        };
      });

    return {
      activeIncidents: incidents || [],
      activeMaintenance:
        components
          .find((c) => c.id === componentId)
          ?.activeMaintenances?.map((maintenance) => {
            return {
              ...maintenance,
              start: new Date(maintenance.start).toISOString(),
              end: new Date(
                new Date(maintenance.start).getTime() +
                  parseInt(maintenance.duration) * 60000
              ).toISOString(),
            };
          }) || [],
    };
  };

  const getStatus = async () => {
    if (summary) {
      return await getSummaryStatus();
    }

    const { components } = await fetchComponents(baseUrl);
    const component = components.find((c) => c.id == componentId);
    return {
      id: component.id,
      name: component.name,
      activeIncidents:
        component.activeIncidents?.map((incident) => {
          return {
            ...incident,
            started: new Date(incident.started).toISOString(),
          };
        }) || [],
      activeMaintenance:
        component.activeMaintenances?.map((maintenance) => {
          return {
            ...maintenance,
            start: new Date(maintenance.start).toISOString(),
            end: new Date(
              new Date(maintenance.start).getTime() +
                parseInt(maintenance.duration) * 60000
            ).toISOString(),
          };
        }) || [],
    };
  };

  return {
    getStatus,
  };
};

export { statusPageClient };
