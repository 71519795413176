import { impactIcon, closeIcon } from './icons';
import { IMPACTS } from './constants';

function incidentTemplate({ name, url, impact, id }) {
  if (!id) {
    return '';
  }
  switch (impact) {
    case IMPACTS.DEGRADEDPERFORMANCE:
    case IMPACTS.PARTIALOUTAGE:
      return `
          <div class="incident incident-warning relative" role="alert">      
          <div class="flex-wrapper">         
            <div class="icon">${impactIcon(impact)}</div>
              <a href=${url} rel="nofollow" target="_blank"><p class="title">${name}</p></a>        
            </div>         
            <button class="close-icon" role="button" data-id=${id}>${closeIcon(
        '#F2994A80'
      )}</button>
          </div>
          `;
    case IMPACTS.MAJOROUTAGE:
      return `
        <div class="incident incident-error relative" role="alert">
        <div class="flex-wrapper">
        <a href=${url} rel="nofollow" target="_blank">
          <div class="icon">${impactIcon(impact)}</div>
            <a href=${url} rel="nofollow" target="_blank"><p class="title">${name}</p></a>  
          </div>
        </a>
          <button class="close-icon" role="button" data-id=${id}>${closeIcon(
        '#D5131780'
      )}</button>
        </div>
        `;
    default:
      return '';
  }
}

function maintenanceTemplate({ name, status, url, id }) {
  if (!id) {
    return '';
  }
  return `
    <div class="incident incident-info relative" role="alert">
    <div class="flex-wrapper">
      <div class="icon">${impactIcon(status)}</div>
        <a href=${url} rel="nofollow" target="_blank"><p class="title">${name}</p></a>  
      <button class="close-icon" role="button" data-id=${id}>${closeIcon(
    '#2F80ED80'
  )}</button>
      </div>
    </div>`;
}

export { incidentTemplate, maintenanceTemplate };
