import { statusPageClient } from './client';
import { incidentTemplate, maintenanceTemplate } from './incident';
import { setCookie, cookieExists } from './cookieHelper';

class SbIncident extends HTMLElement {
  connectedCallback() {
    this.init();
  }

  init() {
    this.client = statusPageClient({
      componentId: this.getAttribute('componentid'),
      isDevelopment: this.getAttribute('development') === 'true',
      summary: this.getAttribute('summary') === 'true',
    });

    this.closeTimer = parseInt(this.getAttribute('closetimer')) || 60;

    this.client.getStatus().then((incidents) => {
      this.incidents = incidents;
      let shadowRoot = this.attachShadow({ mode: 'open' });

      shadowRoot.innerHTML = this.style;
      shadowRoot.innerHTML += this.getIncidents;
      shadowRoot.innerHTML += this.getMaintenance;

      shadowRoot
        .querySelectorAll('.close-icon')
        .forEach((element) =>
          element.addEventListener('click', () =>
            this.handleCloseIncidentClick(element)
          )
        );
    });
  }

  handleCloseIncidentClick(element) {
    const incidentId = element.getAttribute('data-id');

    // User closed this incident, persist it in a cookie.
    setCookie(incidentId, incidentId, this.closeTimer);

    // Find the parent incident element
    const parentIncident = element.closest('.incident');

    if (parentIncident) {
      // fade it out
      parentIncident.classList.add('fade-out');

      // Then remove it from the layout
      parentIncident.addEventListener('transitionend', () => {
        parentIncident.style.display = 'none';
      });
    }
  }

  get style() {
    return `
    <style>
        *,
        *::before,
        *::after {
          box-sizing: border-box;
          margin: 0;
          padding: 0;
          font-family: 'Inter', sans-serif;
          line-height: 15px;
        }
        .incident {
          padding: .4rem .5rem;
          border-radius: 4px;
          margin: 8px 0;
          box-shadow: 0 2px 3px rgba(0,0,0,0.30);
        }
        .incident-warning{
          background: #F2994A40;
          border-left: 6px solid #F2994A;
        }
        .incident-error{
          background: #D5131740;
          border-left: 6px solid #D51317;
        }
        .incident-info{
          background: #2F80ED40;
          border-left: 6px solid #2F80ED;
        }
        .flex-wrapper{
          display: flex;
          align-items: center;
          align-content: center;
        }
        .icon{
          margin-left: 2px;
        }
        .title {
          font-size: 14px;
          margin-left: 20px;
          color: #434A52;
          font-weight: 400;
        }
        a {
          text-decoration: none;
        }
        .relative {
          position: relative;
        }
        .close-icon{
          position: absolute;
          right: 10px;
          top: 8px;
        }
        button{
          background: none;
          color: inherit;
          border: none;
          padding: 0;
          font: inherit;
          cursor: pointer;
          outline: inherit
        }
        .fade-out {
          opacity: 0;
          transition: opacity .8s ease;
        }
      </style>
    `;
  }

  get getMaintenance() {
    let maintenances = this.incidents.activeMaintenance;
    if (maintenances) {
      return maintenances
        .filter((m) => !cookieExists(m.id))
        .map((m) => maintenanceTemplate(m))
        .join('');
    }
  }

  get getIncidents() {
    let incidents = this.incidents.activeIncidents;
    if (incidents) {
      return incidents
        .filter((incident) => !cookieExists(incident.id))
        .map((incident) => incidentTemplate(incident))
        .join('');
    }
  }
}

customElements.define('sb-incident', SbIncident);
